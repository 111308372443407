body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
}

.banner {
    position: fixed;
    background-color: #782F40;
    color: #FFFFFF;
    text-align: center;
    padding: 20px;
    font-size: 42px;
    font-weight: 300;
    left: 0;
    right: 0;
}

.text-container {
    position: relative;
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 42px;
    padding: 100px;
    z-index: 3;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.above-video-text {
    font-size: 42px;
    color: #782F40;
    padding: 20px;
    font-weight: 600;
}

.education {
    font-size: 40px;
}

.education-container {
    text-align: center;
    padding: 20px;
}

.image-container img {
    width: 100%;
    max-width: 490px;
}

p {
    font-size: 25px;
    font-weight: 600;
}

.details {
    font-size: 14px;
    text-transform: uppercase;
}

.video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 16px 0;
}

.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #FFFFFF;
    color: #782F40;
    position: relative;
    z-index: 1;
    width: 100%;
}

.education {
    text-align: center;
    color: #782F40;
    width: 100%;
}

/* Dark Mode styles */
.dark-mode {
    background-color: #1a1a1a;
    color: #ffffff;
    width: 100%;

    .education {
        color: #ffffff;
        width: 100%;
    }

    .above-video-text {
        color: #ffffff;
    }

    .left-microphone,
    .right-microphone,
    .left-microphone2,
    .right-microphone2 {
        filter: invert(100%);
    }
}

/* Toggle Switch Styles */
.toggle-switch {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    z-index: 9999999;
    scale: 120%;
}

.toggle-input {
    display: none;
}

.toggle-label {
    background: #ffffff;
    border-radius: 40px;
    width: 49px;
    height: 24px;
    position: relative;
    cursor: pointer;
    transition: background 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #782F40;
    font-weight: bold;
}

.toggle-slider {
    position: absolute;
    top: 10;
    left: 0;
    right: 0;
    width: 25px;
    height: 24px;
    border-radius: 80%;
    background: #cccccc;
    transition: transform 0.3s;
}

.toggle-input:checked+.toggle-label .toggle-slider {
    transform: translateX(25px);
    background: #333333;
}

.toggle-icon {
    font-size: 20px;
    color: #782F40;
    margin: 0 5px;
}

.microphone-container {
    position: fixed;
    transform: translateY(-20%);
    z-index: -1;
}

.left-microphone {
    position: fixed;
    left: 10px;
    bottom: 0px;
    width: 500px;
    height: 500px;
    z-index: 1;
    opacity: 20%;
}

.right-microphone {
    position: fixed;
    right: 10px;
    bottom: 450px;
    width: 500px;
    height: 500px;
    z-index: 1;
    opacity: 20%;
}

.left-microphone2 {
    position: absolute;
    left: 10px;
    bottom: 800px;
    width: 500px;
    height: 500px;
    z-index: 1;
    opacity: 20%;
}

.right-microphone2 {
    position: absolute;
    right: 10px;
    bottom: -300px;
    width: 500px;
    height: 500px;
    z-index: 1;
    opacity: 20%;
}

@media (max-width: 768px) {

    .banner {
        position: fixed;
        top: 0;
        background-color: #782F40;
        color: #FFFFFF;
        text-align: center;
        padding: 20px;
        font-size: 22px;
        font-weight: 300;
        left: 0;
        right: 0;
    }

    .text-container {
        position: relative;
        width: 100%;
    }

    .toggle-switch {
        position: absolute;
        top: 50%;
        right: 5%;
        transform: translate(0, -50%);
        display: flex;
        align-items: center;
        scale: 110%;
    }

    .content {
        padding: 20px;
        font-size: 24px;
    }

    .above-video-text {
        font-size: 42px;
        color: #782F40;
        padding-top: 20%;
    }

    .video-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        padding-top: 5%;
    }

    .education-container {
        text-align: center;
        padding: 0;
    }

    .microphone-container {
        position: fixed;
        transform: translateY(-20%);
        z-index: -1;
    }

    .left-microphone {
        position: fixed;
        left: 5px;
        bottom: 0px;
        width: 300px;
        height: 300px;
        z-index: 1;
        opacity: 20%;
    }

    .right-microphone {
        position: fixed;
        right: 5px;
        bottom: 450px;
        width: 300px;
        height: 300px;
        z-index: 1;
        opacity: 20%;
    }

    .left-microphone2 {
        position: absolute;
        left: 5px;
        bottom: 800px;
        width: 300px;
        height: 300px;
        z-index: 1;
        opacity: 20%;
    }

    .right-microphone2 {
        position: absolute;
        right: 5px;
        bottom: -300px;
        width: 300px;
        height: 300px;
        z-index: 1;
        opacity: 20%;
    }

}